@font-face {
  font-family: Eina;
  src: url(./assets/Eina03-Regular.ttf);
}

:root {
  --e-global-color-primary: #01A2C8;
  --e-global-color-secondary: #111111;
  --e-global-color-text: #FFFFFF;
  --e-global-color-accent: #FFFFFF2B;
}

html{
  padding: 0;
  margin: 0;
}

body {
  margin: 0; 
  padding: 0; 
  font-family: "Eina";
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}